import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// SEO Image
import ogImage from "../../../../images/seo/datepickers/datepickers.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsDatepickerAllPlainPreview from "../../../../library/components/form-elements/datepicker/react/_preview/plain"
import FormElementsDatepickerAllRoundedPreview from "../../../../library/components/form-elements/datepicker/react/_preview/rounded"

import DatepickerHeroComponent from "../../../../library/components/form-elements/datepicker/react/_preview/DatepickerHeroComponent"

// Plain Input Fields

// Basic base
const FormElementsDatepickerPlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/base/basic.jsx")
const FormElementsDatepickerPlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/base/basic.html")

// Basic lg
const FormElementsDatepickerPlainLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/lg/basic.jsx")
const FormElementsDatepickerPlainLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/lg/basic.html")

// Helper text base
const FormElementsDatepickerPlainBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/base/helper_text.jsx")
const FormElementsDatepickerPlainBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/base/helper_text.html")

// Helper text lg
const FormElementsDatepickerPlainLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/lg/helper_text.jsx")
const FormElementsDatepickerPlainLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/lg/helper_text.html")

// Range date base
const FormElementsDatepickerPlainBaseRangeDateJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/base/range_date.jsx")
const FormElementsDatepickerPlainBaseRangeDateHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/base/range_date.html")

// Range date lg
const FormElementsDatepickerPlainLgRangeDateJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/plain/lg/range_date.jsx")
const FormElementsDatepickerPlainLgRangeDateHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/plain/lg/range_date.html")

// Rounded Input Fields
// Basic base
const FormElementsDatepickerRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/base/basic.jsx")
const FormElementsDatepickerRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/base/basic.html")

// Basic lg
const FormElementsDatepickerRoundedLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/lg/basic.jsx")
const FormElementsDatepickerRoundedLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/lg/basic.html")

// Helper text base
const FormElementsDatepickerRoundedBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/base/helper_text.jsx")
const FormElementsDatepickerRoundedBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/base/helper_text.html")

// Helper text lg
const FormElementsDatepickerRoundedLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/lg/helper_text.jsx")
const FormElementsDatepickerRoundedLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/lg/helper_text.html")

// Range date base
const FormElementsDatepickerRoundedBaseRangeDateJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/base/range_date.jsx")
const FormElementsDatepickerRoundedBaseRangeDateHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/base/range_date.html")

// Range date lg
const FormElementsDatepickerRoundedLgRangeDateJsx = require("!!raw-loader!../../../../library/components/form-elements/datepicker/react/rounded/lg/range_date.jsx")
const FormElementsDatepickerRoundedLgRangeDateHTML = require("!!raw-loader!../../../../library/components/form-elements/datepicker/html/rounded/lg/range_date.html")

export default function FormElementDatepickersPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsBasicDatepicker",
      title: "Basic datepickers",
      active_tab: 1,
    },
    {
      component_name: "FormElementsDatepickerRounded",
      title: "Outline",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Datepickers - WindUI Component Library"
        ogtitle="Tailwind CSS Datepickers - WindUI Component Library"
        description="The Date Picker component lets users select a date. Built with Tailwind CSS by WindUI."
        ogdescription="The Date Picker component lets users select a date. Built with Tailwind CSS by WindUI."
        url="components/form-elements/datepickers/"
        author="Wind UI"
        ogimage={ogImage}
        keywords="Tailwind CSS, Tailwind CSS Datepickers"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Datepickers</h1>
          <p>The Date Picker component lets users select a date.</p>

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <DatepickerHeroComponent />
            </section>
          </div>
        </Section>
        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Input Basic Section */}
          <h3 id="FormElementsBasicDatepicker">Basic Datepicker Input</h3>

          <PreviewBlock
            id="FormElementsBasicDatepicker"
            HtmlComponent={FormElementsDatepickerPlainBaseBasicHTML.default}
            JsxComponent={FormElementsDatepickerPlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsDatepickerAllPlainPreview
                FormElementsDatepickerPlainBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainBaseBasicHTML.default
                    : FormElementsDatepickerPlainBaseBasicJsx.default
                }
                FormElementsDatepickerPlainLgBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainLgBasicHTML.default
                    : FormElementsDatepickerPlainLgBasicJsx.default
                }
                FormElementsDatepickerPlainBaseHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainBaseHelperTextHTML.default
                    : FormElementsDatepickerPlainBaseHelperTextJsx.default
                }
                FormElementsDatepickerPlainLgHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainLgHelperTextHTML.default
                    : FormElementsDatepickerPlainLgHelperTextJsx.default
                }
                FormElementsDatepickerPlainBaseRangeDate={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainBaseRangeDateHTML.default
                    : FormElementsDatepickerPlainBaseRangeDateJsx.default
                }
                FormElementsDatepickerPlainLgRangeDate={
                  activeTabs[0].active_tab === 1
                    ? FormElementsDatepickerPlainLgRangeDateHTML.default
                    : FormElementsDatepickerPlainLgRangeDateJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Input Outlined Section */}
          <h3 id="FormElementsDatepickerRounded">Outline Datepicker Input</h3>

          <PreviewBlock
            id="FormElementsDatepickerRounded"
            HtmlComponent={FormElementsDatepickerRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsDatepickerRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <FormElementsDatepickerAllRoundedPreview
                FormElementsDatepickerRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedBaseBasicHTML.default
                    : FormElementsDatepickerRoundedBaseBasicJsx.default
                }
                FormElementsDatepickerRoundedLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedLgBasicHTML.default
                    : FormElementsDatepickerRoundedLgBasicJsx.default
                }
                FormElementsDatepickerRoundedBaseHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedBaseHelperTextHTML.default
                    : FormElementsDatepickerRoundedBaseHelperTextJsx.default
                }
                FormElementsDatepickerRoundedLgHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedLgHelperTextHTML.default
                    : FormElementsDatepickerRoundedLgHelperTextJsx.default
                }
                FormElementsDatepickerRoundedBaseRangeDate={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedBaseRangeDateHTML.default
                    : FormElementsDatepickerRoundedBaseRangeDateJsx.default
                }
                FormElementsDatepickerRoundedLgRangeDate={
                  activeTabs[1].active_tab === 1
                    ? FormElementsDatepickerRoundedLgRangeDateHTML.default
                    : FormElementsDatepickerRoundedLgRangeDateJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>
        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Date pickers let people select a date. They should be suitable for
            the context in which they appear. Date pickers can be embedded into:
          </p>
          <ul>
            <li>Dialogs</li>
            <li>Forms</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Datepickers fields come in two basic styles (<strong>base</strong>{" "}
            and <strong>outlined</strong>) and 2 sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> text font size of 0.875rem (14px) and a
              label that scales from 0.875rem (14px) in font size, when the
              input is empty or 0.75rem (12px) when the label is floated. Helper
              text is also 0.75rem (12px) and a top and bottom margin of 1.5rem
              (24px) is used to house both the floating label and the helper
              text.
            </li>
            <li>
              <strong>Large:</strong> text font size of 1rem (16px) and a label
              that scales from 1rem (16px) in font size, when the input is empty
              or 0.75rem (12px) when the label is floated. Helper text is also
              0.75rem (12px) and a top and bottom margin of 1.5rem (24px) is
              used to house both the floating label and the helper text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Date Pickers are built with the native{" "}
            <code>&lt;input type="date"&gt;</code> element and no additional
            accessibility roles are required. For better accessibility when
            using the element:
          </p>
          <ul>
            <li>
              An id attribute to allow the date <code>&lt;input&gt;</code> to be
              associated with a <code>&lt;label&gt;</code> element for
              accessibility purposes.
            </li>
            <li>
              A name attribute to set the name of the associated data point
              submitted to the server when the form is submitted.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
