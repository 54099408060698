import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsDatepickerAllPlainPreview(props) {
  const {
    FormElementsDatepickerPlainBaseBasic,
    FormElementsDatepickerPlainBaseRangeDate,
    FormElementsDatepickerPlainBaseHelperText,
    FormElementsDatepickerPlainLgBasic,
    FormElementsDatepickerPlainLgHelperText,
    FormElementsDatepickerPlainLgRangeDate,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="font-medium text-slate-500">Base Size:</h3>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainBaseBasic}
              componentName="FormElementsDatepickerPlainBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic base sized datepicker --> */}
              <div className="relative my-6">
                <input
                  id="id-date01"
                  type="date"
                  name="id-date01"
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date01"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
              </div>
              {/*<!-- End Basic base sized datepicker --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainBaseHelperText}
              componentName="FormElementsDatepickerPlainBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Base sized datepicker with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-date02"
                  type="date"
                  name="id-date02"
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date02"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Please select a date</span>
                </small>
              </div>
              {/*<!-- End Base sized datepicker with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainBaseRangeDate}
              componentName="FormElementsDatepickerPlainBaseRangeDate"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Base sized datepicker with range dates --> */}
              <div className="relative my-6">
                <input
                  id="id-date03"
                  type="date"
                  name="id-date03"
                  min="2020-01-01"
                  max="2023-12-31"
                  className="peer relative h-10 w-full border-b border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date03"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Select a date between 2020 and 2023</span>
                </small>
              </div>
              {/*<!-- End Base sized datepicker with range dates --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="w-full">
          <h3 className="font-medium text-slate-500">Large Size:</h3>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainLgBasic}
              componentName="FormElementsDatepickerPlainLgBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Basic large datepicker --> */}
              <div className="relative my-6">
                <input
                  id="id-date04"
                  type="date"
                  name="id-date04"
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date04"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
              </div>
              {/*<!-- End Basic large datepicker --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainLgHelperText}
              componentName="FormElementsDatepickerPlainLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Large datepicker with helper text--> */}
              <div className="relative my-6">
                <input
                  id="id-date05"
                  type="date"
                  name="id-date05"
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date05"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Please select a date</span>
                </small>
              </div>
              {/*<!-- End Large datepicker with helper text--> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerPlainLgRangeDate}
              componentName="FormElementsDatepickerPlainLgRangeDate"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Large datepicker with range date --> */}
              <div className="relative my-6">
                <input
                  id="id-date06"
                  type="date"
                  name="id-date06"
                  min="2020-01-01"
                  max="2023-12-31"
                  className="peer relative h-12 w-full border-b border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date06"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Select a date between 2020 and 2023</span>
                </small>
              </div>
              {/*<!-- End Large datepicker with range date --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
