import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsDatepickerAllRoundedPreview(props) {
  const {
    FormElementsDatepickerRoundedBaseBasic,
    FormElementsDatepickerRoundedBaseHelperText,
    FormElementsDatepickerRoundedBaseRangeDate,
    FormElementsDatepickerRoundedLgBasic,
    FormElementsDatepickerRoundedLgHelperText,
    FormElementsDatepickerRoundedLgRangeDate,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="font-medium text-slate-500">Base Size:</h3>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedBaseBasic}
              componentName="FormElementsDatepickerRoundedBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded basic base sized datepicker --> */}
              <div className="relative my-6">
                <input
                  id="id-date07"
                  type="date"
                  name="id-date07"
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date07"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
              </div>
              {/*<!-- End Rounded basic base sized datepicker --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedBaseHelperText}
              componentName="FormElementsDatepickerRoundedBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded base sized datepicker with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-date08"
                  type="date"
                  name="id-date08"
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date08"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Please select a date</span>
                </small>
              </div>
              {/*<!-- End Rounded base sized datepicker with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedBaseRangeDate}
              componentName="FormElementsDatepickerRoundedBaseRangeDate"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded base sized datepicker with range date --> */}
              <div className="relative my-6">
                <input
                  id="id-date09"
                  type="date"
                  name="id-date09"
                  min="2020-01-01"
                  max="2023-12-31"
                  className="peer relative h-10 w-full rounded border border-slate-200 px-4 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date09"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Select a date between 2020 and 2023</span>
                </small>
              </div>
              {/*<!-- End Rounded base sized datepicker with range date --> */}
            </CopyComponent>
          </div>
        </div>

        <div className="w-full">
          <h3 className="font-medium text-slate-500">Large Size:</h3>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedLgBasic}
              componentName="FormElementsDatepickerRoundedLgBasic"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded basic large datepicker  --> */}
              <div className="relative my-6">
                <input
                  id="id-date10"
                  type="date"
                  name="id-date10"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date10"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
              </div>
              {/*<!-- End Rounded basic large datepicker  --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedLgHelperText}
              componentName="FormElementsDatepickerRoundedLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded large datepicker with helper text --> */}
              <div className="relative my-6">
                <input
                  id="id-date11"
                  type="date"
                  name="id-date11"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date11"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Please select a date</span>
                </small>
              </div>
              {/*<!-- End Rounded large datepicker with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-10 h-24">
            <CopyComponent
              copyToClipboardCode={FormElementsDatepickerRoundedLgRangeDate}
              componentName="FormElementsDatepickerRoundedLgRangeDate"
              extraClasses="copy-block-container"
            >
              {/*<!-- Component: Rounded large datepicker with range date --> */}
              <div className="relative my-6">
                <input
                  id="id-date12"
                  type="date"
                  name="id-date12"
                  min="2020-01-01"
                  max="2023-12-31"
                  className="peer relative h-12 w-full rounded border border-slate-200 px-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                />
                <label
                  for="id-date12"
                  className="absolute -top-2 left-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:left-0 before:top-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-autofill:-top-2 peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Date
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Select a date between 2020 and 2023</span>
                </small>
              </div>
              {/*<!-- End Rounded large datepicker with range date --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
